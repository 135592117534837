import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { SignupButton, Row, Typography,  Container, Section, FeatureRows } from "components/ui"
import Image from "components/image"

const FindAndRestoreBackup = () => (
  <Layout>
		<SEO refKey="find-and-restore-backup" />
    <Section color="alt">
      <Container>
        <Row>
          <div className="lg:grid grid-cols-12 gap-20">
            <div className="lg:col-span-6">
              <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">
                Navigate through your backup archives
              </h1>
              <Typography tag="p" className="mt-2">
                Navigate all your backups from our search page.<br/>
                You can from this simple interface, <strong>download</strong>, <strong>restore</strong> or review the logs of a specific backup.
                No need to access your storage to download an archive.
              </Typography>
              <SignupButton className="mt-12" urlAtrr={{ sb_source: "website", sb_term: "features" }} noSubtitle text="Add your first backup now"/>
            </div>
            <div className="hidden lg:col-span-6 lg:block">
              <Image filename="simplebackups-find-logs.png" alt="SimpleBackups find and restore file and database backups" style={{maxWidth: '1000px'}}/>
            </div>
          </div>
        </Row>
      </Container>
    </Section>

    <Section>
      <Container>
        <Row>
          <FeatureRows items={
            [
              {
                title: "Restore file and database backups in minutes",
                description: (
                  <>
                    <p>
                      Every backup instance can be restored using our generated commands (available for website as well as any database backup).
                      Select the backup you want to restore - copy the command - paste it on your server... you're done!
                    </p>
                  </>
                ),
                image: (<Image filename="simplebackups-restore.png" alt="SimpleBackups restore file and database backup" style={{maxWidth: '484px'}}/>)
              }]}/>
        </Row>
      </Container>
    </Section>
  </Layout>
)

export const frontmatter = {
  pageMetaData: {
    refKey: "find-and-restore-backup",
    title: "Find and restore file and database backups, simply.",
    description: "Navigate all your backups from one single interface. No need to access any server or remote storage. Restore your backups in one click.",
    author: "simplebackups.com"
  }
}

export default FindAndRestoreBackup
